import { IconFiles, IconGps, IconReportSearch, IconUsers, type TablerIcon } from '@tabler/icons'
import { IconDashboard, IconDeviceDesktopCog, IconUsersGroup } from '@tabler/icons-react'
import type { TFunction } from 'i18next'
import router from 'next/router'

import type { UserPermissionsAttributesApiDTO } from 'src/codegen/generated/Api.schemas'

const ICON_PROPS = {
  size: 14,
  stroke: 1.5,
  style: {
    marginRight: '0.5rem',
  },
}

export const MenuHelper = {
  getLinks: (t: TFunction<'common'>): MenuLink[] => {
    return [
      {
        label: t('words.administration'),
        icon: <IconDeviceDesktopCog {...ICON_PROPS} />,
        IconElement: IconDeviceDesktopCog,
        subLinks: [
          {
            link: '/projects/',
            label: t('words.project_other'),
            icon: <IconFiles {...ICON_PROPS} />,
            IconElement: IconFiles,
            permission: 'canManageProjects',
          },
          {
            link: '/pollsters/',
            label: t('words.pollster_other'),
            icon: <IconReportSearch {...ICON_PROPS} />,
            IconElement: IconReportSearch,
            permission: 'canManagePollsters',
            divider: true,
          },
          {
            link: '/users/',
            label: t('words.user_other'),
            icon: <IconUsers {...ICON_PROPS} />,
            IconElement: IconUsers,
            permission: 'canManageUsers',
          },
        ],
      },
      {
        label: t('words.client_other'),
        icon: <IconUsersGroup {...ICON_PROPS} />,
        IconElement: IconUsersGroup,
        permission: 'canViewClientReports',
        subLinks: [
          {
            link: '/dashboards/',
            label: t('words.dashboard_other'),
            icon: <IconDashboard {...ICON_PROPS} />,
            IconElement: IconDashboard,
            permission: 'canViewClientReports',
          },
        ],
      },
      {
        link: '/field/',
        label: t('fieldOffice.title'),
        icon: <IconGps {...ICON_PROPS} />,
        IconElement: IconGps,
        permission: 'canManagePollsters',
      },
    ]
  },
  getActiveLink: (links: MenuLink[]): MenuLink | undefined => {
    if (router.pathname === '/') {
      return undefined
    }

    return links.find(l => {
      if (l.subLinks) {
        return !!l.subLinks.find(sl =>
          router.pathname.startsWith(`/${sl.link?.replaceAll('/', '')}`),
        )
      }

      return (
        l.link && l.link !== '/' && router.pathname.startsWith(`/${l.link?.replaceAll('/', '')}`)
      )
    })
  },
  ICON_PROPS,
}

export type MenuLink = {
  label: string
  link?: string
  icon?: JSX.Element
  IconElement?: TablerIcon
  permission?: keyof UserPermissionsAttributesApiDTO
  divider?: boolean
  subLinks?: MenuLink[]
}
